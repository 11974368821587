import Link from "next/link";
import { Spinner } from "react-bootstrap";
import * as H from "../Header.styles";
import HeaderCategory from "../Header-category";
import Search from "./search/Search";
import { Sticker } from "../../ecommerce/singleProduct/SingleProduct-styles";
import { priceComma } from "../../../common/splicePrice";
import { ToggleButton } from "../../../common/toggle";

const SecondHeader = ({
  scroll,
  logo,
  banners,
  categoriRef,
  setToggled,
  menus,
  decideUrl,
  showUserName,
  isUser,
  authHandler,
  getCookie,
  basketsCount,
  toggleClick,
  userInfo,
  userCoupons,
  notificationPermission,
  onClickToggleBtn,
  totalCartItems,
  openSearch,
  setOpenSearch,
  scrollSearchBtnRef,
  isToggleActive,
}) => {
  return (
    <>
      <div
        className={
          scroll
            ? "header-bottom header-bottom-bg-color sticky-bar stick"
            : "header-bottom header-bottom-bg-color sticky-bar"
        }
      >
        <div className="container">
          <div className="header-wrap header-space-between position-relative">
            {/* 모바일 로고 */}
            <div className="logo logo-width-1 d-block d-lg-none">
              <Link href="/">
                <a style={{ display: "flex", justifyContent: "center" }}>
                  {logo?.mobile && logo.mobile !== "" ? (
                    <img src={logo.mobile} alt="mobile_logo" />
                  ) : (
                    <Spinner animation="border" role="status" />
                  )}
                </a>
              </Link>
            </div>
            {/* 헤더 좌측 카테고리 버튼 */}
            <div className="header-nav d-none d-lg-flex">
              <H.CategoryWrapper
                ref={categoriRef}
                className="main-categori-wrap d-none d-lg-block"
              >
                <span className="main_category_button">
                  <i className="fi-rs-apps" />
                  상품 카테고리
                  <i className="fi-rs-angle-down" />
                </span>
                {/* 카테고리 내부 */}
                <div
                  className={
                    "categories-dropdown-wrap categories-dropdown-active-large d-flex flex-column align-items-center font-heading"
                  }
                >
                  <HeaderCategory
                    setToggled={setToggled}
                    categoriRef={categoriRef}
                  />
                </div>
              </H.CategoryWrapper>
            </div>
            {/* 메뉴 네비게이션 */}
            <div className="header-nav d-none d-lg-flex">
              <H.MenuNavigation
                className={
                  "main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading"
                }
              >
                <nav>
                  <ul>{menus}</ul>
                </nav>
              </H.MenuNavigation>
            </div>
            {/* 헤더 우측 유저, 장바구니 등 */}
            <div
              style={{
                display: "flex",
              }}
              className="header-nav d-none d-lg-flex"
            >
              {/* 스크롤 시 */}
              <H.SearchIcon
                className={scroll ? "d-flex" : "d-none"}
                ref={scrollSearchBtnRef}
              >
                {!openSearch && (
                  <i
                    className="fi-rs-search"
                    onClick={() => setOpenSearch((prev) => !prev)}
                  />
                )}
              </H.SearchIcon>
              <nav>
                <ul
                  className="d-flex justify-content-end"
                  style={{ minWidth: "160px" }}
                >
                  <li className="">
                    <div className="header-action-2">
                      <div className="header-action-icon-2 position-relative">
                        {!scroll && decideUrl === "login" && (
                          <img
                            src="https://file.caretbio.com/caretbio/b4d44e283d9fe834c3f77d62d7de889c.png"
                            alt="register_tooltip"
                            style={{
                              position: "absolute",
                              maxWidth: "none",
                              left: "-50px",
                              bottom: "30px",
                            }}
                          />
                        )}
                        <Link href={"/" + `${decideUrl}`}>
                          <a>
                            <img
                              className="svgInject"
                              src="/assets/imgs/theme/icons/icon-user.svg"
                            />
                          </a>
                        </Link>
                        <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                          <ul>
                            {showUserName ? (
                              <>
                                <div className="d-flex justify-content-start align-items-center">
                                  {showUserName}님
                                </div>
                                <li>
                                  <a>
                                    <Sticker className="mr-10">P</Sticker>
                                    {priceComma(userInfo?.point || 0)} P
                                  </a>
                                </li>
                                {Number(userCoupons?.total_count) > 0 && (
                                  <H.CouponInfo>
                                    <Link href="/mypage/coupon">
                                      <a>
                                        <i className="fi-rs-gift-card mr-10" />
                                        쿠폰: {userCoupons?.total_count} 장
                                      </a>
                                    </Link>
                                    {/* <div className="list">
                                      {userCoupons.rows.map((coupon, i) => (
                                        <div key={i}>
                                          <span className="price">
                                            {priceComma(coupon.price)}원 할인
                                            쿠폰
                                          </span>
                                          <span className="limit">
                                            ({coupon?.edate?.split(" ")[0]}
                                            까지 사용가능)
                                          </span>
                                        </div>
                                      ))}
                                    </div> */}
                                  </H.CouponInfo>
                                )}
                                {notificationPermission === "granted" && (
                                  <H.NotificationArea>
                                    <i className="fi fi-rs-bell mt-1 mr-10"></i>
                                    <span>알림</span>
                                    <ToggleButton
                                      width="48px"
                                      height="24px"
                                      fontSize="10px"
                                      bgColor="#b6b6b6"
                                      btnWidth="24px"
                                      btnBgColor="#3bb77e"
                                      btnPadding="2px 4px"
                                      btnLeft="22px"
                                      disabledColor="#707070"
                                      disabledLeft="2px"
                                      mlAuto="auto"
                                      isToggleActive={isToggleActive}
                                      onClickToggleBtn={onClickToggleBtn}
                                    />
                                  </H.NotificationArea>
                                )}
                                <li>
                                  <Link href="/mypage/order">
                                    <a>
                                      <i className="fi fi-rs-user mr-10"></i>
                                      마이페이지
                                    </a>
                                  </Link>
                                </li>
                              </>
                            ) : (
                              ""
                            )}
                            {!isUser && (
                              <li>
                                <Link href="/order-search">
                                  <a>
                                    <i className="fi fi-rs-barcode mr-10"></i>
                                    주문 조회
                                  </a>
                                </Link>
                              </li>
                            )}
                            <li onClick={authHandler}>
                              <button
                                type="button"
                                className="header-nav-logout-btn"
                              >
                                {isUser ? (
                                  <>
                                    <i className="fi-rs-log-out mr-10" />
                                    {"로그아웃"}
                                  </>
                                ) : (
                                  <>
                                    <i className="fi-rs-lock-alt mr-10" />
                                    {"로그인"}
                                  </>
                                )}
                              </button>
                            </li>
                            {!isUser && (
                              <li>
                                <Link href={"/register/"}>
                                  <a>
                                    <i className="fi-rs-sign-out-alt mr-10" />
                                    회원 가입
                                  </a>
                                </Link>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {isUser ? (
                    <li>
                      <div className="header-action-2">
                        <div className="header-action-icon-2">
                          <Link href="/shop-wishlist">
                            <a>
                              <img
                                className="svgInject"
                                src="/assets/imgs/theme/icons/icon-heart.svg"
                              />
                            </a>
                          </Link>
                        </div>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                  <li>
                    <div className="header-action-2">
                      <div className="header-action-icon-2">
                        <Link href="/shop-cart">
                          <a className="mini-cart-icon">
                            <img src="/assets/imgs/theme/icons/icon-cart.svg" />
                            <span className="pro-count blue">
                              {getCookie("token")
                                ? basketsCount
                                : totalCartItems}
                            </span>
                          </a>
                        </Link>
                        <Link href="/shop-cart">
                          <a></a>
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            {/* mobile header */}
            <div className="header-action-icon-2 d-flex d-lg-none">
              <div
                className="burger-icon burger-icon-white"
                onClick={toggleClick}
              >
                <span className="burger-icon-top"></span>
                <span className="burger-icon-mid"></span>
                <span className="burger-icon-bottom"></span>
              </div>
            </div>
            <div className="header-action-right d-block d-lg-none">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0",
                }}
                className="header-action-2"
              >
                {isUser && (
                  <div className="header-action-icon-2">
                    <Link href="/shop-wishlist">
                      <a>
                        <img src="/assets/imgs/theme/icons/icon-heart.svg" />
                      </a>
                    </Link>
                  </div>
                )}
                <div className="header-action-icon-2">
                  <Link href="/shop-cart">
                    <a className="mini-cart-icon">
                      <img src="/assets/imgs/theme/icons/icon-cart.svg" />
                      <span className="pro-count white">
                        {basketsCount || totalCartItems}
                      </span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SecondHeader;
